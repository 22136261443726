import { MESSAGE_TYPE } from 'shared/constants/messages';
import { applyPageFilters, sortComparator } from './helpers';

export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

const getters = {
  getAllConversations: ({ allConversations, chatSortFilter: sortKey }) => {
    return allConversations.sort((a, b) => sortComparator(a, b, sortKey));
  },
  getSelectedChat: ({ selectedChatId, allConversations }) => {
    const selectedChat = allConversations.find(
      conversation => conversation.id === selectedChatId
    );
    return selectedChat || {};
  },
  getSelectedChatAttachments: (_state, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    return selectedChat.attachments || [];
  },
  getLastEmailInSelectedChat: (stage, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    const { messages = [] } = selectedChat;
    const lastEmail = [...messages].reverse().find(message => {
      const {
        content_attributes: contentAttributes = {},
        message_type: messageType,
      } = message;
      const { email = {} } = contentAttributes;
      const isIncomingOrOutgoing =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.INCOMING;
      if (email.from && isIncomingOrOutgoing) {
        return true;
      }
      return false;
    });

    return lastEmail;
  },
  getAppliedConversationFilters: _state => {
    return _state.appliedFilters;
  },
  getConversationType: _state => {
    return _state.conversationType;
  },
  getMineChats: (_state, _getters) => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const { assignee } = conversation.meta;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isPermitted = _getters.getMineChatPermission({
        assigneeId: assignee?.id,
      });
      const isChatMine = isPermitted && shouldFilter;

      return isChatMine;
    });
  },
  getUnAssignedChats: (_state, _getters) => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const { team, assignee } = conversation.meta;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isPermitted = _getters.getUnAssignedChatPermission({
        teamId: team?.id,
        assigneeId: assignee?.id,
      });
      return isPermitted && shouldFilter;
    });
  },
  getAllStatusChats: (_state, _getters) => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const { assignee, team } = conversation.meta;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isPermitted = _getters.getChatPermission({
        teamId: team?.id,
        assigneeId: assignee?.id,
      });
      return isPermitted && shouldFilter;
    });
  },
  getChatListLoadingStatus: ({ listLoadingStatus }) => listLoadingStatus,
  getAllMessagesLoaded(_state) {
    const [chat] = getSelectedChatConversation(_state);
    return !chat || chat.allMessagesLoaded === undefined
      ? false
      : chat.allMessagesLoaded;
  },
  getUnreadCount(_state) {
    const [chat] = getSelectedChatConversation(_state);
    if (!chat) return [];
    return chat.messages.filter(
      chatMessage =>
        chatMessage.created_at * 1000 > chat.agent_last_seen_at * 1000 &&
        chatMessage.message_type === 0 &&
        chatMessage.private !== true
    ).length;
  },
  getChatStatusFilter: ({ chatStatusFilter }) => chatStatusFilter,
  getChatSortFilter: ({ chatSortFilter }) => chatSortFilter,
  getSelectedInbox: ({ currentInbox }) => currentInbox,
  getConversationById: _state => conversationId => {
    return _state.allConversations.find(
      value => value.id === Number(conversationId)
    );
  },
  getConversationParticipants: _state => {
    return _state.conversationParticipants;
  },
  getConversationLastSeen: _state => {
    return _state.conversationLastSeen;
  },
  getWatcherPermission:
    (_, _getters, ___, rootGetters) =>
    ({ conversationWatchers = [] }) => {
      const currentUserId = rootGetters.getCurrentUser?.id || {};
      const hasCurrentUser = conversationWatchers.some(
        ({ id }) => id === currentUserId
      );

      return hasCurrentUser;
    },
  getTeamChatsPermission:
    (_, __, ___, rootGetters) =>
    ({ assigneeId, teamId }) => {
      // Get conversation permission to specific team
      const currentUser = rootGetters.getCurrentUser || {};
      const currentUserTeamsId = rootGetters.getCurrentUserTeamsId;
      const isAssignedToMe = assigneeId === currentUser.id;
      const isUndefinedAssigned = assigneeId === undefined;
      const isMemberTeam = currentUserTeamsId.includes(teamId);

      return isMemberTeam && (isUndefinedAssigned || isAssignedToMe);
    },
  getMineChatPermission:
    (_, _getters, ___, rootGetters) =>
    ({ assigneeId }) => {
      const currentUser = rootGetters.getCurrentUser || {};
      const isAssignedToMe = assigneeId === currentUser.id;

      // Mention Conversation section
      const isMentionSection = _getters.getConversationType === 'mention';
      const mentionPermission = isMentionSection && isAssignedToMe;

      return isAssignedToMe || mentionPermission;
    },
  getUnAssignedChatPermission:
    (_, _getters, __, rootGetters) =>
    ({ assigneeId, teamId }) => {
      const userRole = rootGetters.getCurrentUser?.role;
      const isUndefinedAssigned = assigneeId === undefined;
      const teamPermission = _getters.getTeamChatsPermission({ teamId });
      const isAdministrator = userRole === 'administrator';
      const isSupervisor = userRole === 'supervisor';

      // All conversation and Not Answered Section
      const isConversationPermission =
        isUndefinedAssigned &&
        (teamPermission || isAdministrator || (teamPermission && isSupervisor));

      // Mention Conversation section
      const isMentionSection = _getters.getConversationType === 'mention';
      const mentionPermission = isMentionSection && isUndefinedAssigned;

      return isConversationPermission || mentionPermission;
    },
  getChatPermission:
    (_, _getters, __, rootGetters) =>
    ({ teamId, assigneeId }) => {
      const isAdministrator =
        rootGetters.getCurrentUser?.role === 'administrator';

      // User Supervisor
      const isSupervisor = rootGetters.getCurrentUser?.role === 'supervisor';
      const isSupervisorPermission =
        isSupervisor && _getters.getTeamChatsPermission({ teamId });

      const unAssignedChatPermission = _getters.getUnAssignedChatPermission({
        teamId,
        assigneeId,
      });

      const mineChatPermission = _getters.getMineChatPermission({
        teamId,
        assigneeId,
      });

      const isMentionSection = _getters.getConversationType === 'mention';

      return (
        isAdministrator ||
        unAssignedChatPermission ||
        mineChatPermission ||
        isSupervisorPermission ||
        isMentionSection
      );
    },
};

export default getters;
